import React, {BaseSyntheticEvent} from 'react';

import styled from 'styled-components';

import {ReactComponent as PlusIcon} from '../../../../resources/icons/plus_icon.svg';

const FilesPickerContainer = styled.div`
  .file-input {
    display: none;
  }
  .input-label {
    cursor: pointer;
  }
`;

const AddLayerCardButton = ({
  isMultiple,
  onAddFiles,
}: {
  isMultiple: boolean;
  onAddFiles?: (e: BaseSyntheticEvent) => void;
}) => {
  return (
    <FilesPickerContainer>
      {isMultiple ? (
        <div>
          <input
            className={'file-input'}
            id={'multiple-add'}
            type={'file'}
            dir={''}
            multiple
            /* @ts-expect-error */
            directory=""
            webkitdirectory=""
            onChange={onAddFiles}
          />
          <label className={'input-label'} htmlFor={'multiple-add'}>
            <PlusIcon />
          </label>
        </div>
      ) : (
        <div>
          <input className={'file-input'} id={'single-add'} type={'file'} onChange={onAddFiles} />
          <label className={'input-label'} htmlFor={'single-add'}>
            <PlusIcon />
          </label>
        </div>
      )}
    </FilesPickerContainer>
  );
};

export default AddLayerCardButton;
