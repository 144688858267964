// eslint-disable-next-line import/named
import {DefaultTheme} from 'styled-components';

export const theme: DefaultTheme = {
  colors: {
    backgroundMain: '#131622',
    backgroundForm: '#1E2333',
    white: '#FFFFFF',
    mainPurple: '#BD56ED',
    gray: '#7A849D',
  },
};
