import styled from 'styled-components';

export enum TEXT_BLOCK_TYPE {
  H2,
  H3,
  H4,
  P1,
}

const TextBlock = styled.div<{type: TEXT_BLOCK_TYPE}>(({theme, type}) => {
  let fontSize;
  let fontWeight;
  let lineHeight = 'normal';

  switch (type) {
    case TEXT_BLOCK_TYPE.H2:
      fontSize = '48px';
      lineHeight = '48px';
      fontWeight = 700;
      break;
    case TEXT_BLOCK_TYPE.H3:
      fontSize = '32px';
      lineHeight = '32px';
      fontWeight = 700;
      break;
    case TEXT_BLOCK_TYPE.H4:
      fontSize = '24px';
      lineHeight = '24px';
      fontWeight = 700;
      break;
    case TEXT_BLOCK_TYPE.P1:
      fontWeight = 700;
      fontSize = '24px';
      lineHeight = '24px';
      break;
  }

  return `
      font-family: Montserrat;
      color: ${theme.colors.white};
      font-size: ${fontSize};
      font-weight: ${fontWeight};
      line-height: ${lineHeight}
    `;
});

export default TextBlock;
