import React from 'react';

import styled from 'styled-components';

const SelectWrapper = styled.div`
  .title {
    font-weight: bold;
    color: #ffffff;
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 5px;
  }

  .select {
    min-width: 185px;
  }
`;

export interface CustomSelectItem {
  value: string | number;
  label: string;
}

export type CustomSelectItemsList = Array<CustomSelectItem>;

interface CustomSelectProps {
  title: string;
  name: string;
  value: string | number;
  options: CustomSelectItemsList;
  onChange: (e) => void;
}

const CustomSelect = ({title, name, onChange, value, options}: CustomSelectProps): JSX.Element => {
  return (
    <SelectWrapper>
      <div className={'title'}>{title}</div>
      <select className={'select'} name={name} onChange={onChange} value={value}>
        <option value={''}>{'none'}</option>
        {options.map((option) => (
          <option value={option.value}>{option.label}</option>
        ))}
      </select>
    </SelectWrapper>
  );
};

export default CustomSelect;
