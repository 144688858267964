import React, {BaseSyntheticEvent} from 'react';

import styled from 'styled-components';

import {LayerImageData} from '../../store/editorPage/types';
import TextBlock, {TEXT_BLOCK_TYPE} from '../uiKit/TextBlock';

import LayerPreviewCard from './components/LayerPreviewCard';

const MainContainer = styled.div`
  background: ${({theme}) => theme.colors.backgroundMain};
  flex: 1;

  .cards-grid {
    height: calc(100vh - 244px);
    overflow: auto;
    padding: 7px 26px 0 32px;
    grid-template-columns: repeat(auto-fill, 186px);
    display: grid;
    grid-gap: 16px;
  }
`;

const Header = styled.div`
  text-align: center;
  margin: 24px 0 33px;
`;

const LayersPreview = ({
  layerName,
  onAddFiles,
  images,
  onRemoveFile,
  onImageClick,
}: {
  layerName: string;
  onAddFiles: (e: BaseSyntheticEvent) => void;
  onRemoveFile: (imageIndex: number) => void;
  images: Array<LayerImageData>;
  onImageClick: (imageIndex: number) => void;
}): JSX.Element => {
  return (
    <MainContainer>
      <Header>
        <TextBlock type={TEXT_BLOCK_TYPE.H2}>{layerName}</TextBlock>
      </Header>
      <div className={'cards-grid'}>
        {images.map((imageData, index) => (
          <LayerPreviewCard
            imageIndex={index}
            imageData={imageData}
            key={imageData.base64Image}
            onImageClick={onImageClick}
            onRemoveFile={() => onRemoveFile(index)}
          />
        ))}
        <LayerPreviewCard
          onAddFiles={onAddFiles}
          text={'Add new layer variant'}
          isAddSingleLayerCard={true}
        />
        <LayerPreviewCard
          onAddFiles={onAddFiles}
          text={'Add new layers pack'}
          isAddMultipleLayersCard={true}
        />
      </div>
    </MainContainer>
  );
};

export default LayersPreview;
