import React from 'react';

import styled from 'styled-components';

const InputWrapper = styled.div`
  .title {
    font-weight: bold;
    color: #ffffff;
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 5px;
  }

  .input {
  }
`;

interface CustomCustomInputProps {
  title: string;
  name: string;
  value: string | number;
  onChange: (e) => void;
}

const CustomInput = ({title, name, onChange, value}: CustomCustomInputProps): JSX.Element => {
  return (
    <InputWrapper>
      <div className={'title'}>{title}</div>
      <input className={'input'} name={name} onChange={onChange} value={value} />
    </InputWrapper>
  );
};

export default CustomInput;
