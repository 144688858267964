import React from 'react';

import styled from 'styled-components';

const TextAreaWrapper = styled.div`
  .title {
    font-weight: bold;
    color: #ffffff;
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 5px;
  }

  .textarea {
    height: 4em;
    width: 50em;
  }
`;

interface CustomCustomTextAreaProps {
  title: string;
  name: string;
  value: string | number;
  onChange: (e) => void;
}

const CustomTextArea = ({title, name, onChange, value}: CustomCustomTextAreaProps): JSX.Element => {
  return (
    <TextAreaWrapper>
      <div className={'title'}>{title}</div>
      <textarea className={'textarea'} name={name} onChange={onChange} value={value} />
    </TextAreaWrapper>
  );
};

export default CustomTextArea;
