import {createStore, applyMiddleware} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import {logger} from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './common/rootReducer';
import rootSaga from './common/rootSaga';

const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware, logger];

const composedEnhancers = composeWithDevTools(applyMiddleware(...middleware));

const store = createStore(rootReducer, composedEnhancers);

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof rootReducer>;

export default store;
