// eslint-disable-next-line import/named
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {constructEventHandlingReducers, EventHandlingState} from '../../utils/events';

import {EDITOR_PAGE_EVENT, GenerationConfigFormData, LayerImageData, LayersData} from './types';

interface EditorPageReducerState extends EventHandlingState<EDITOR_PAGE_EVENT> {
  layersData: LayersData;
  isProcessing: boolean;
  configData: GenerationConfigFormData;
  editingImageIndex: number;
  activeLayer: number;
}

const editorPageInitialState: EditorPageReducerState = {
  layersData: [],
  configData: {
    downloadConfigOnly: true,
    blockchainSelect: '',
    onlyDataGeneration: false,
    generationCount: 1000,
    newOwnerAddress: '',
    collectionName: 'New collection',
    collectionItemName: '',
    collectionItemBaseDescription: '',
    showRarity: false,
    defaultCost: 50000000000000000000,
    presaleCost: 1000000000000000000,
  },
  isProcessing: false,
  activeLayer: undefined,
  editingImageIndex: undefined,
  events: {backStack: []},
};

const editorPageSlice = createSlice({
  name: 'editorPage',
  initialState: editorPageInitialState,
  reducers: {
    ...constructEventHandlingReducers<EDITOR_PAGE_EVENT>(),
    moveLayer: (state, action: PayloadAction<{currentIndex: number; nextIndex: number}>) => {
      const {currentIndex, nextIndex} = action.payload;
      const temp = state.layersData[currentIndex];
      state.layersData[currentIndex] = state.layersData[nextIndex];
      state.layersData[nextIndex] = temp;
    },
    setActiveLayer: (state, action: PayloadAction<number>) => {
      state.activeLayer = action.payload;
    },
    setIsProcessing: (state, action: PayloadAction<boolean>) => {
      state.isProcessing = action.payload;
    },
    setEditingImageIndex: (state, action: PayloadAction<number>) => {
      state.editingImageIndex = action.payload;
    },
    updateLayerName: (state, action: PayloadAction<{index: number; value: string}>) => {
      const {index, value} = action.payload;
      state.layersData[index].layerName = value;
    },
    addLayer: (state) => {
      const newLayersData = [...state.layersData, {layerName: 'New layer', imagesData: []}];
      state.layersData = newLayersData;
      state.activeLayer = newLayersData.length - 1;
    },
    deleteLayer: (state, action: PayloadAction<number>) => {
      state.layersData.splice(action.payload, 1);
    },
    updateImageProperty: (state, action: PayloadAction<Partial<LayerImageData>>) => {
      const {activeLayer, editingImageIndex} = state;
      Object.assign(state.layersData[activeLayer].imagesData[editingImageIndex], action.payload);
    },
    removeImage: (state, action: PayloadAction<number>) => {
      state.layersData[state.activeLayer].imagesData.splice(action.payload, 1);
    },
    updateLayerImagesData: (
      state,
      action: PayloadAction<{layerIndex: number; imagesData: Array<LayerImageData>}>,
    ) => {
      const {layerIndex, imagesData} = action.payload;

      state.layersData[layerIndex].imagesData = [
        ...state.layersData[layerIndex].imagesData,
        ...imagesData,
      ];
    },
    updateGenerationConfigData: (
      state,
      action: PayloadAction<Partial<GenerationConfigFormData>>,
    ) => {
      Object.assign(state.configData || {}, action.payload);
    },
  },
});

export default editorPageSlice;
