import React from 'react';

import styled from 'styled-components';

import {ReactComponent as HeaderLeftBorder} from '../../resources/icons/Header_left_border.svg';
import {ReactComponent as HeaderRightBorder} from '../../resources/icons/Header_righ_border.svg';
import NFactoryLogo from '../../resources/images/NFACTORY.png';
import TextBlock, {TEXT_BLOCK_TYPE} from '../uiKit/TextBlock';

const HeaderContainer = styled.div`
  background: ${({theme}) => theme.colors.backgroundMain};
  padding: 0 52px 0 62px;
  display: flex;
  height: 88px;
  border: 4px solid ${({theme}) => theme.colors.mainPurple};
  position: relative;
  align-items: center;
  justify-content: space-between;

  .logo {
    width: 381px;
  }
`;

const StyledLeftIcon = styled.img`
  position: absolute;
  left: 0;
  top: 0;
`;

const StyledRightIcon = styled.img`
  position: absolute;
  right: 0;
  top: 0;
`;

const RightPartContainer = styled.div`
  z-index: 1000;
`;

const StyledSignInSignOutTextBtn = styled(TextBlock)`
  cursor: pointer;
  user-select: none;
`;

const Header = () => {
  return (
    <HeaderContainer>
      <StyledLeftIcon as={HeaderLeftBorder} />
      <img className={'logo'} src={NFactoryLogo} alt={''} />
      <RightPartContainer>
        {/*<StyledSignInSignOutTextBtn type={TEXT_BLOCK_TYPE.H3}>
          Sign in / log in
        </StyledSignInSignOutTextBtn>*/}
      </RightPartContainer>
      <StyledRightIcon as={HeaderRightBorder} />
    </HeaderContainer>
  );
};

export default Header;
