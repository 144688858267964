export enum EDITOR_PAGE_EVENT {
  ERROR = 'ERROR',
  SUCCESS_CONFIG_UPLOAD = 'SUCCESS_CONFIG_UPLOAD',
}

export interface ProcessPickedFilesActionPayload {
  files: Array<File>;
  activeLayer: number;
}

export interface PickedFile extends File {
  lastModifiedDate?: Date;
}

export interface LayerImageData {
  base64Image: string;
  lastModified: number;
  isColorable: boolean;
  isCountable: boolean;
  isGif: boolean;
  showMeta: boolean;
  count: number;
  lastModifiedDate: Date;
  name: string;
  nameWithExtension: string;
  rarity: string;
  size: number;
  type: string;
  webkitRelativePath: string;
  weight: number;
  connectedWith?: string;
  extraDescription: string;
}

export interface GenerationConfigFormData {
  collectionName: string;
  collectionItemName: string;
  collectionItemBaseDescription: string;
  generationCount: number;
  blockchainSelect: string;
  onlyDataGeneration: boolean;
  downloadConfigOnly: boolean;
  newOwnerAddress: string;
  showRarity: boolean;
  defaultCost: number;
  presaleCost: number;
}

export interface GenerationConfig extends GenerationConfigFormData {
  layers_order: Array<string>;
}

export interface MetaConfigItem {
  path: string;
  is_colorable: boolean;
  is_countable: true;
  is_gif: false;
  show_meta: true;
  count: number;
  weight: number;
  rarity: string;
  layer_name: string;
  public_name: string;
}

export type EditableLayerImageData = Partial<LayerImageData>;

export interface LayerData {
  layerName: string;
  imagesData: Array<LayerImageData>;
}

export type LayersData = Array<LayerData>;
