import {LayerImageData, PickedFile} from '../store/editorPage/types';

export async function processSingleNftPartFile(file: PickedFile): Promise<LayerImageData> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      resolve({
        base64Image: reader.result as string,
        lastModified: file.lastModified,
        lastModifiedDate: file.lastModifiedDate,
        name: file.name.split('.')[0],
        nameWithExtension: file.name,
        size: file.size,
        type: file.type,
        rarity: 'normal',
        isColorable: true,
        isCountable: false,
        isGif: file.name.includes('.gif'),
        showMeta: true,
        count: 0,
        webkitRelativePath: file.webkitRelativePath,
        weight: 10,
        connectedWith: '',
        extraDescription: '',
      });
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
}
