import React from 'react';

import styled from 'styled-components';

const CheckboxWrapper = styled.div`
  .title {
    font-weight: bold;
    color: #ffffff;
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 5px;
  }

  .checkbox {
  }
`;

interface CustomCheckboxProps {
  title: string;
  name: string;
  value: boolean;
  onChange: (e) => void;
}

const CustomCheckbox = ({title, name, onChange, value}: CustomCheckboxProps): JSX.Element => {
  return (
    <CheckboxWrapper>
      <div className={'title'}>{title}</div>
      <input
        className={'checkbox'}
        name={name}
        type={'checkbox'}
        onChange={onChange}
        checked={value}
      />
    </CheckboxWrapper>
  );
};

export default CustomCheckbox;
