import React, {BaseSyntheticEvent, useCallback, useEffect, useMemo, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import CustomModal from '../../components/CustomModal';
import ImagePropertiesEditList from '../../components/ImagePropertiesEditList';
import LayersList, {LayersListData} from '../../components/LayersList';
import LayersPreview from '../../components/LayersPreview';
import CustomButton, {BUTTON_TYPE} from '../../components/uiKit/CustomButton';
import CustomCheckbox from '../../components/uiKit/CustomCheckbox';
import CustomInput from '../../components/uiKit/CustomInput';
import CustomSelect from '../../components/uiKit/CustomSelect';
import CustomTextArea from '../../components/uiKit/CustomTextArea';
import TextBlock, {TEXT_BLOCK_TYPE} from '../../components/uiKit/TextBlock';
// @ts-ignore
import ImageBackground from '../../resources/images/image_background_big.png';
import {EditorPageActions} from '../../store/common/reducerActions';
import {EditorPageSagaActions} from '../../store/common/sagaActions';
import {
  selectAllImagesData,
  selectCurrentActiveLayer,
  selectCurrentEditingImageIndex,
  selectCurrentEditorPageEvent,
  selectCurrentLayersData,
  selectEditingImageData,
  selectGenerationConfigFormData,
  selectIsEditorPageProcessing,
} from '../../store/editorPage/selectors';
import {EDITOR_PAGE_EVENT, LayerImageData} from '../../store/editorPage/types';

const EditorPageContainer = styled.div`
  display: flex;
`;

const PropertiesEditModal = styled(CustomModal)`
  .modal-content {
    min-width: 1200px;
  }

  .content-wrapper {
    display: flex;
    gap: 59px;
  }
`;

const GenerationConfigModal = styled(CustomModal)`
  .modal-content-container {
    display: flex;
    flex-direction: column;

    .config-inputs-wrapper {
      padding: 0 150px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      max-height: 400px;
      overflow: auto;
    }

    .export-button {
      align-self: center;
      margin-top: 48px;
      max-width: 474px;
    }
  }
`;

const ResultUrlModal = styled(CustomModal)`
  .content-wrapper {
    display: flex;
    flex-direction: column;

    .link {
      font-size: 32px;
      line-height: 32px;
      text-align: center;
      color: ${({theme}) => theme.colors.white};
    }

    .dismiss-button {
      align-self: center;
      margin-top: 48px;
      max-width: 474px;
    }
  }
`;

const EmptyLayersPreview = styled.div`
  background: ${({theme}) => theme.colors.backgroundMain};
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImagePreviewContainer = styled.div`
  background: center url(${ImageBackground}) no-repeat;
  width: 512px;
  background-size: contain;
  height: 512px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 32px;
  overflow: hidden;
  .image {
    width: 100%;
  }
`;

const Editor = (): JSX.Element => {
  const layersData = useSelector(selectCurrentLayersData);
  const activeLayer = useSelector(selectCurrentActiveLayer);
  const allImagesNames = useSelector(selectAllImagesData);
  const isPageProcessing = useSelector(selectIsEditorPageProcessing);
  const editingImageData = useSelector(selectEditingImageData);
  const editingImageIndex = useSelector(selectCurrentEditingImageIndex);
  const generationConfigFormData = useSelector(selectGenerationConfigFormData);
  const event = useSelector(selectCurrentEditorPageEvent);

  const [isImageEditModalVisible, setIsImageEditModalVisible] = useState(false);
  const [isConfigModalVisible, setIsConfigModalVisible] = useState(false);
  const [isResultUrlModalVisible, setIsResultModalVisible] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (event) {
      switch (event.type) {
        case EDITOR_PAGE_EVENT.SUCCESS_CONFIG_UPLOAD:
          dispatch(EditorPageActions.handleCurrentEvent());
          setIsResultModalVisible(true);
      }
    }
  }, [dispatch, event]);

  const onLayerNameChange = useCallback(
    (layerIndex: number, newValue: string) => {
      dispatch(EditorPageActions.updateLayerName({index: layerIndex, value: newValue}));
    },
    [dispatch],
  );

  const constructedToDisplayLayersData = useMemo<LayersListData>(() => {
    return layersData.map(({layerName}) => ({
      layerName,
      onNameChange: onLayerNameChange,
    }));
  }, [layersData, onLayerNameChange]);

  const onAddLayer = useCallback(() => {
    if (isPageProcessing) return;
    dispatch(EditorPageActions.addLayer());
  }, [dispatch, isPageProcessing]);

  const onMoveLayer = useCallback(
    (currentIndex: number, nextIndex: number) => {
      if (layersData.length === nextIndex || nextIndex < 0 || isPageProcessing) return;
      dispatch(EditorPageActions.moveLayer({currentIndex, nextIndex}));
    },
    [dispatch, isPageProcessing, layersData.length],
  );

  const onLayerSelect = useCallback(
    (index: number) => {
      if (isPageProcessing) return;
      dispatch(EditorPageActions.setActiveLayer(index));
    },
    [dispatch, isPageProcessing],
  );

  const processAddedFiles = useCallback(
    (e: BaseSyntheticEvent) => {
      dispatch(EditorPageSagaActions.processPickedFiles({files: e.target.files, activeLayer}));
      e.target.value = null;
    },
    [activeLayer, dispatch],
  );

  const onRemoveFile = useCallback(
    (imageIndex: number) => {
      dispatch(EditorPageActions.removeImage(imageIndex));
    },
    [dispatch],
  );

  const onExportLayers = useCallback(() => {
    setIsConfigModalVisible(false);
    dispatch(EditorPageSagaActions.exportLayers());
  }, [dispatch]);

  const onImageClick = useCallback(
    (imageIndex: number) => {
      dispatch(EditorPageActions.setEditingImageIndex(imageIndex));
      setIsImageEditModalVisible(true);
    },
    [dispatch],
  );

  const onImagePropertyChange = useCallback(
    (propertyData: Partial<LayerImageData>) => {
      dispatch(EditorPageActions.updateImageProperty(propertyData));
    },
    [dispatch],
  );

  const onGenerationFormDataChange = useCallback(
    (e) => {
      const {type, name, value, checked} = e.target;

      if (type === 'checkbox') {
        dispatch(EditorPageActions.updateGenerationConfigData({[name]: checked}));
      } else {
        dispatch(EditorPageActions.updateGenerationConfigData({[name]: value}));
      }
    },
    [dispatch],
  );

  const onDeleteLayer = useCallback(
    (layerIndex: number) => {
      dispatch(EditorPageActions.deleteLayer(layerIndex));
    },
    [dispatch],
  );

  // kek wait
  return (
    <EditorPageContainer>
      <ResultUrlModal
        isVisible={isResultUrlModalVisible}
        headerText={'Result'}
        closeHandler={() => setIsResultModalVisible(false)}>
        <div className={'content-wrapper'}>
          <a
            target={'_blank'}
            className={'link'}
            href={`https://api.ncraftsman.com/static/${generationConfigFormData?.collectionName}/`}>
            {`https://api.ncraftsman.com/static/${generationConfigFormData?.collectionName}/`}
          </a>
          <CustomButton
            title={'OK'}
            className={'dismiss-button'}
            onClick={() => setIsResultModalVisible(false)}
            buttonType={BUTTON_TYPE.THIN}
          />
        </div>
      </ResultUrlModal>
      <GenerationConfigModal
        isVisible={isConfigModalVisible}
        headerText={'Generation config'}
        closeHandler={() => setIsConfigModalVisible(false)}>
        <div className={'modal-content-container'}>
          <div className={'config-inputs-wrapper'}>
            <CustomCheckbox
              title={'Download config only'}
              name={'downloadConfigOnly'}
              value={generationConfigFormData.downloadConfigOnly}
              onChange={onGenerationFormDataChange}
            />
            <CustomSelect
              value={generationConfigFormData.blockchainSelect}
              title={'Blockchain select'}
              name={'blockchainSelect'}
              onChange={onGenerationFormDataChange}
              //TODO blockchain dropdown items
              options={[
                {label: 'Polygon Mainnet', value: 'Polygon Mainnet'},
                {label: 'Polygon Mumbai Testnet', value: 'Polygon Mumbai Testnet'},
                {label: 'Ethereum Mainnet', value: 'Ethereum Mainnet'},
                {label: 'Ethereum Testnet Rinkeby', value: 'Ethereum Testnet Rinkeby'},
              ]}
            />
            <CustomInput
              title={'Collection name'}
              name={'collectionName'}
              value={generationConfigFormData.collectionName}
              onChange={onGenerationFormDataChange}
            />
            <CustomInput
              title={'Collection item name'}
              name={'collectionItemName'}
              value={generationConfigFormData.collectionItemName}
              onChange={onGenerationFormDataChange}
            />
            <CustomTextArea
              title={'Collection item base description'}
              name={'collectionItemBaseDescription'}
              value={generationConfigFormData.collectionItemBaseDescription}
              onChange={onGenerationFormDataChange}
            />
            <CustomInput
              title={'Generation count'}
              name={'generationCount'}
              value={generationConfigFormData.generationCount}
              onChange={onGenerationFormDataChange}
            />
            <CustomCheckbox
              title={'Only data generation'}
              name={'onlyDataGeneration'}
              value={generationConfigFormData.onlyDataGeneration}
              onChange={onGenerationFormDataChange}
            />
            <CustomInput
              title={'New owner address'}
              name={'newOwnerAddress'}
              value={generationConfigFormData.newOwnerAddress}
              onChange={onGenerationFormDataChange}
            />
            <CustomCheckbox
              title={'ShowRarity'}
              name={'showRarity'}
              value={generationConfigFormData.showRarity}
              onChange={onGenerationFormDataChange}
            />
            <CustomInput
              title={'Default cost'}
              name={'defaultCost'}
              value={generationConfigFormData.defaultCost}
              onChange={onGenerationFormDataChange}
            />
            <CustomInput
              title={'Presale cost'}
              name={'presaleCost'}
              value={generationConfigFormData.presaleCost}
              onChange={onGenerationFormDataChange}
            />
          </div>
          <CustomButton
            title={'Export'}
            className={'export-button'}
            onClick={onExportLayers}
            buttonType={BUTTON_TYPE.THIN}
          />
        </div>
      </GenerationConfigModal>
      <PropertiesEditModal
        isVisible={isImageEditModalVisible}
        headerText={'Edit component properties'}
        closeHandler={() => setIsImageEditModalVisible(false)}>
        <div className={'content-wrapper'}>
          <ImagePreviewContainer>
            <img className={'image'} src={editingImageData?.base64Image} alt={''} />
          </ImagePreviewContainer>
          <ImagePropertiesEditList
            allImagesNames={allImagesNames}
            onImagePropertyChange={onImagePropertyChange}
            imageData={editingImageData}
            imageIndex={editingImageIndex}
          />
        </div>
      </PropertiesEditModal>
      <LayersList
        layers={constructedToDisplayLayersData}
        onAddLayer={onAddLayer}
        onMoveLayer={onMoveLayer}
        onLayerSelect={onLayerSelect}
        onExportLayers={() => setIsConfigModalVisible(true)}
        onDeleteLayer={onDeleteLayer}
      />
      {!layersData.length ? (
        <EmptyLayersPreview>
          <TextBlock type={TEXT_BLOCK_TYPE.H2}>Add layer to start</TextBlock>
        </EmptyLayersPreview>
      ) : (
        <LayersPreview
          onImageClick={onImageClick}
          images={layersData[activeLayer].imagesData}
          onRemoveFile={onRemoveFile}
          onAddFiles={processAddedFiles}
          layerName={layersData[activeLayer].layerName}
        />
      )}
    </EditorPageContainer>
  );
};

export default Editor;
