import React from 'react';

import {Route, Switch} from 'react-router-dom';
import styled from 'styled-components';

import Header from '../components/Header';

import Editor from './Editor';

const StyledRouter = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
`;

const Router = () => {
  return (
    <StyledRouter>
      <Header />
      <Switch>
        <Route path={'/'} exact={true} component={Editor} />
      </Switch>
    </StyledRouter>
  );
};

export default Router;
