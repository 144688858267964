import React, {useCallback, useState} from 'react';

import {useSelector} from 'react-redux';
import styled from 'styled-components';

import {selectCurrentActiveLayer} from '../../store/editorPage/selectors';
import CustomButton from '../uiKit/CustomButton';
import TextBlock, {TEXT_BLOCK_TYPE} from '../uiKit/TextBlock';

import LayersListItem, {LayersListItemProps} from './components/LayersListItem';

const LayersListMainWrapper = styled.div`
  background: ${({theme}) => theme.colors.backgroundForm};
  width: 448px;
  height: 100%;
`;

const LayersListHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 28px 26px 30px 32px;
`;

const StyledAddItemTextBtn = styled(TextBlock)`
  cursor: pointer;
  user-select: none;
`;

const LayersItemsContainer = styled.div`
  padding: 0 16px;
  height: calc(100vh - 310px);
  overflow: auto;
`;

const LayersListFooter = styled.div`
  padding: 26px 36px;

  .export-button {
    height: 76px;
  }
`;

export type LayersListData = Array<Partial<LayersListItemProps>>;

const LayersList = ({
  layers,
  onAddLayer,
  onMoveLayer,
  onLayerSelect,
  onExportLayers,
  onDeleteLayer,
}: {
  layers: LayersListData;
  onAddLayer: () => void;
  onMoveLayer: (currentIndex: number, nextIndex: number) => void;
  onLayerSelect: (index: number) => void;
  onExportLayers: () => void;
  onDeleteLayer: (layerIndex: number) => void,
}): JSX.Element => {
  const activeLayer = useSelector(selectCurrentActiveLayer);
  return (
    <LayersListMainWrapper>
      <LayersListHeaderContainer>
        <TextBlock type={TEXT_BLOCK_TYPE.H3}>Layers</TextBlock>
        <StyledAddItemTextBtn onClick={onAddLayer} type={TEXT_BLOCK_TYPE.H3}>
          +
        </StyledAddItemTextBtn>
      </LayersListHeaderContainer>
      <LayersItemsContainer>
        {layers.map((item, index) => (
          <LayersListItem
            key={index}
            index={index}
            layerName={item.layerName}
            onNameChange={item.onNameChange}
            onMoveLayer={onMoveLayer}
            onLayerSelect={onLayerSelect}
            activeLayer={activeLayer}
            onDeleteLayer={onDeleteLayer}
          />
        ))}
      </LayersItemsContainer>
      <LayersListFooter>
        <CustomButton onClick={onExportLayers} className={'export-button'} title={'Generate'} />
      </LayersListFooter>
    </LayersListMainWrapper>
  );
};

export default LayersList;
