import {enableMapSet} from 'immer';
import {combineReducers} from 'redux';

import editorPageSlice from '../editorPage/reducers';

enableMapSet();

const appReducer = combineReducers({
  editorPage: editorPageSlice.reducer,
});

const rootReducer = (state: any, action: any) => {
  return appReducer(state, action);
};

export default rootReducer;
