import React from 'react';

import styled from 'styled-components';

import TextBlock, {TEXT_BLOCK_TYPE} from '../TextBlock';

export enum BUTTON_TYPE {
  THIN = 'THIN',
  THICK = 'THICK',
}

interface CustomButtonProps {
  title: string;
  onClick?: () => void;
  buttonType?: BUTTON_TYPE;
  className?: string;
}

const StyledButton = styled.button`
  height: 96px;
  border-radius: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  width: 100%;
  background: ${({theme}) => theme.colors.mainPurple};
`;

const CustomButton = ({title, onClick, className}: CustomButtonProps): JSX.Element => {
  return (
    <StyledButton className={className} onClick={onClick}>
      <TextBlock className={'button-title'} type={TEXT_BLOCK_TYPE.H2}>
        {title}
      </TextBlock>
    </StyledButton>
  );
};

export default CustomButton;
