import {RootState} from '../store';

import {LayersData} from './types';

export const selectCurrentLayersData = (state: RootState): LayersData =>
  state.editorPage.layersData;

export const selectIsEditorPageProcessing = (state: RootState) => state.editorPage.isProcessing;

export const selectCurrentActiveLayer = (state: RootState) => state.editorPage.activeLayer;

export const selectCurrentEditingImageIndex = (state: RootState) =>
  state.editorPage.editingImageIndex;

export const selectEditingImageData = (state: RootState) => {
  const {layersData, activeLayer, editingImageIndex} = state.editorPage;
  return layersData[activeLayer]?.imagesData?.[editingImageIndex];
};

export const selectGenerationConfigFormData = (state: RootState) => {
  return state.editorPage.configData;
};

export const selectAllImagesData = (state: RootState) => {
  return state.editorPage.layersData.reduce((reducer, layerData) => {
    return reducer.concat(layerData.imagesData.map((imageData) => imageData.name));
  }, []);
};

export const selectCurrentEditorPageEvent = (state: RootState) => state.editorPage.events.currEvent;
