import {EditableLayerImageData} from '../store/editorPage/types';

export enum SPECIFIC_FIELDS_TYPES {
  IMAGES_DROPDOWN = 'IMAGES_DROPDOWN',
  TEXT_AREA = 'TEXT_AREA',
}

export enum SPECIFIC_FIELDS_DEFINITION {
  connectedWith = SPECIFIC_FIELDS_TYPES.IMAGES_DROPDOWN,
  extraDescription = SPECIFIC_FIELDS_TYPES.TEXT_AREA,
}

export const EDITABLE_IMAGE_PROPERTIES: Record<
  keyof Omit<
    EditableLayerImageData,
    | 'base64Image'
    | 'webkitRelativePath'
    | 'packedSourceFileData'
    | 'nameWithExtension'
    | 'type'
    | 'size'
    | 'rarity'
    | 'lastModifiedDate'
    | 'lastModified'
  >,
  string
> = {
  isColorable: 'Is colorable',
  count: 'Count',
  isCountable: 'Is countable',
  isGif: 'Is gif',
  showMeta: 'Show meta',
  weight: 'Image weight',
  name: 'Name',
  connectedWith: 'Connected with',
  //TODO in image properties edit this must be a textarea?
  extraDescription: 'Extra description',
};
