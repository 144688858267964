import {takeEvery} from 'redux-saga/effects';

import {exportLayers, processPickedFiles} from '../editorPage/sagas';

import {EditorPageSagaActions} from './sagaActions';

export default function* watcherSaga() {
  yield takeEvery(EditorPageSagaActions.processPickedFiles, processPickedFiles);
  yield takeEvery(EditorPageSagaActions.exportLayers, exportLayers);
}
