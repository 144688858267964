import React from 'react';

import styled from 'styled-components';

import {ReactComponent as CloseIcon} from '../../resources/icons/close_icon.svg';
import TextBlock, {TEXT_BLOCK_TYPE} from '../uiKit/TextBlock';

const ModalWrapper = styled.div<{isVisible}>`
  position: absolute;
  display: ${({isVisible}) => (isVisible ? 'block' : 'none')};
  height: 100%;
  width: 100%;
  z-index: 8000;
  top: 0;
  background: #131622f0;

  .modal-content {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    width: fit-content;
    min-width: 1000px;
    border-radius: 16px;
    background: ${({theme}) => theme.colors.backgroundForm};
    padding: 0 32px 24px;
  }

  .modal-header {
    padding: 57px 0 32px;
    text-align: center;
  }

  .modal-footer {
    display: flex;
    justify-content: center;
    padding: 48px 0 24px;
  }
`;

const StyledCloseIcon = styled.img`
  position: absolute;
  right: 32px;
  top: 32px;
  cursor: pointer;
`;

interface CustomModalProps {
  isVisible: boolean;
  className?: string;
  children?: React.ReactNode;
  headerText?: string;
  footerContent?: JSX.Element;
  closeHandler: () => void;
}

const CustomModal = ({
  isVisible,
  className,
  children,
  headerText,
  footerContent,
  closeHandler,
}: CustomModalProps): JSX.Element => {
  return (
    <ModalWrapper className={className} isVisible={isVisible} onClick={closeHandler}>
      <div onClick={(e) => e.stopPropagation()} className={'modal-content'}>
        <StyledCloseIcon as={CloseIcon} onClick={closeHandler} />
        {!!headerText && (
          <TextBlock className={'modal-header'} type={TEXT_BLOCK_TYPE.H2}>
            {headerText}
          </TextBlock>
        )}
        {children}
        {!!footerContent && <div className={'modal-footer'}>{footerContent}</div>}
      </div>
    </ModalWrapper>
  );
};

export default CustomModal;
