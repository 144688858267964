import {createAction} from '@reduxjs/toolkit';

import {ProcessPickedFilesActionPayload} from './types';

const SAGA_NAME_PREFIX = 'saga/editorPage/';

export const processPickedFiles = createAction<ProcessPickedFilesActionPayload>(
  SAGA_NAME_PREFIX + 'processPickedFiles',
);

export const exportLayers = createAction(SAGA_NAME_PREFIX + 'exportLayers');
