import React, {useCallback, useState} from 'react';

import styled from 'styled-components';

const StyledInput = styled.input`
  flex: 1;
  margin: 0 12px 0 21px;
  background: none;
  color: white;
  outline: none;
  border: none;
  width: 100%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
`;

const LayerNameInput = ({
  value,
  onChange,
  inputIndex,
}: {
  value: string;
  onChange: (index: number, value: string) => void;
  inputIndex: number;
}): JSX.Element => {
  const onBlur = useCallback(() => {
    if (value === '') onChange(inputIndex, 'Empty');
  }, [inputIndex, onChange, value]);

  return (
    <StyledInput
      onChange={(e) => onChange(inputIndex, e.target.value)}
      value={value}
      onBlur={onBlur}
    />
  );
};

export default LayerNameInput;
