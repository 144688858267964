// eslint-disable-next-line import/named
import {PayloadAction} from '@reduxjs/toolkit';
import {all, call, put, select} from 'typed-redux-saga';

import {configUpload} from '../../services/backend/queries/generation';
import {processSingleNftPartFile} from '../../utils/fileProcessing';
import {exportToJsonFile} from '../../utils/jsonExport';
import {EditorPageActions} from '../common/reducerActions';

import {selectCurrentLayersData, selectGenerationConfigFormData} from './selectors';
import {EDITOR_PAGE_EVENT, GenerationConfig, ProcessPickedFilesActionPayload} from './types';

export function* processPickedFiles({payload}: PayloadAction<ProcessPickedFilesActionPayload>) {
  const {files, activeLayer} = payload;
  try {
    const filesArray = Array.from(files).filter(file => file.name.endsWith('.png'));

    yield put(EditorPageActions.setIsProcessing(true));
    //TODO filter not allowed files and if all not allowed throw event
    const filesData = yield all(filesArray.map((file) => processSingleNftPartFile(file)));

    yield put(
      EditorPageActions.updateLayerImagesData({imagesData: filesData, layerIndex: activeLayer}),
    );
  } catch (e) {
    yield put(EditorPageActions.postEvent({type: EDITOR_PAGE_EVENT.ERROR, payload: e.message}));
  } finally {
    yield put(EditorPageActions.setIsProcessing(false));
  }
}

export function* exportLayers(): Generator {
  try {
    yield put(EditorPageActions.setIsProcessing(true));
    const layersData = yield* select(selectCurrentLayersData);
    const generationConfigFormData = yield* select(selectGenerationConfigFormData);

    // TODO general config settings
    const generationConfig: GenerationConfig = {
      ...generationConfigFormData,
      layers_order: layersData.map((layerData) => layerData.layerName),
    };

    const metaConfig = [];
    layersData.forEach((layerData, index) => {
      layerData.imagesData.forEach((imageData) =>
        metaConfig.push({
          imageBase64: imageData.base64Image,
          is_colorable: imageData.isColorable,
          is_countable: imageData.isCountable,
          is_gif: imageData.isGif,
          show_meta: imageData.showMeta,
          count: imageData.count,
          weight: imageData.weight,
          rarity: imageData.rarity,
          layer_name: layerData.layerName,
          public_name: imageData.name,
          connected_with: imageData.connectedWith,
          extra_description: imageData.extraDescription
        }),
      );
    });
    // kek wait
    if (generationConfig.downloadConfigOnly) {
      const configJson = {config: generationConfig, meta: metaConfig};
      exportToJsonFile(configJson, generationConfig.collectionName + '_config.json');
    } else {
      yield call(configUpload, generationConfig, metaConfig);
      yield put(EditorPageActions.postEvent({type: EDITOR_PAGE_EVENT.SUCCESS_CONFIG_UPLOAD}));
    }

  } catch (e) {
    yield put(EditorPageActions.postEvent({type: EDITOR_PAGE_EVENT.ERROR, payload: e.message}));
  } finally {
    yield put(EditorPageActions.setIsProcessing(false));
  }
}
