import React from 'react';

import styled from 'styled-components';

import {ReactComponent as ArrowUpIcon} from '../../../../resources/icons/arrow_up.svg';
import CloseIcon from '../../../../resources/icons/close_icon.svg';
import TextBlock, {TEXT_BLOCK_TYPE} from '../../../uiKit/TextBlock';
import LayerNameInput from '../LayerNameInput';

const ItemContainer = styled.div<{isLayerSelected: boolean}>`
  position: relative;
  border-radius: 16px;
  display: flex;
  align-items: center;
  background: ${({theme, isLayerSelected}) =>
    isLayerSelected ? theme.colors.mainPurple : theme.colors.backgroundMain};
  padding-right: 32px;
  height: 96px;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const ArrowsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StyledArrowUpIcon = styled.img`
  transform: rotate(0.5turn);
  cursor: pointer;
`;
const StyledArrowDownIcon = styled.img`
  cursor: pointer;
`;

const LeftPartWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 32px;
`;

const RemoveLayerIcon = styled.img`
  position: absolute;
  top: 10px;
  left: 10px;
  height: 16px;
  cursor: pointer;
`;

export interface LayersListItemProps {
  index: number;
  layerName: string;
  onNameChange: (index: number, value: string) => void;
  onMoveLayer: (currentIndex: number, nextIndex: number) => void;
  onLayerSelect: (index: number) => void;
  activeLayer: number | undefined;
  onDeleteLayer: (layerIndex: number) => void;
}

const LayersListItem = ({
  layerName,
  onNameChange,
  index,
  onMoveLayer,
  onLayerSelect,
  activeLayer,
  onDeleteLayer,
}: LayersListItemProps): JSX.Element => {
  return (
    <ItemContainer isLayerSelected={activeLayer === index}>
      <RemoveLayerIcon onClick={() => onDeleteLayer(index)} src={CloseIcon} alt={''} />
      <LeftPartWrapper onClick={() => onLayerSelect(index)}>
        <TextBlock type={TEXT_BLOCK_TYPE.P1}>{index + 1}</TextBlock>
        <LayerNameInput onChange={onNameChange} inputIndex={index} value={layerName} />
      </LeftPartWrapper>
      <ArrowsWrapper>
        <StyledArrowUpIcon onClick={() => onMoveLayer(index, index - 1)} as={ArrowUpIcon} />
        <StyledArrowDownIcon onClick={() => onMoveLayer(index, index + 1)} as={ArrowUpIcon} />
      </ArrowsWrapper>
    </ItemContainer>
  );
};

export default LayersListItem;
