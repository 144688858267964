import React, {BaseSyntheticEvent} from 'react';

import styled from 'styled-components';

import ImageBackground from '../../../../resources/images/background.png';
import CloseIcon from '../../../../resources/images/close_icon.png';
import {LayerImageData} from '../../../../store/editorPage/types';
import TextBlock, {TEXT_BLOCK_TYPE} from '../../../uiKit/TextBlock';
import AddLayerCardButton from '../AddLayerCardButton';

const CardContainer = styled.div<{
  isAdditionalCard: boolean;
}>`
  max-height: 412px;
  width: 186px;
  height: max-content;
  border-radius: 32px;
  background: ${({theme}) => theme.colors.backgroundForm};
  padding-bottom: 12px;
  position: relative;

  .remove-image-icon {
    position: absolute;
    right: -3px;
    top: -7px;
    cursor: pointer;
  }

  .image-preview-container {
    border-radius: 32px;
    width: 186px;
    height: 186px;
    margin-bottom: 12px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({isAdditionalCard, theme}) =>
      isAdditionalCard ? theme.colors.mainPurple : 'url("' + ImageBackground + '")'};
  }

  .image-preview-container__image-preview {
    width: 100%;
    cursor: pointer;
  }

  .layer-description-text {
    text-align: center;
  }
`;

const LayerPreviewCard = ({
  imageIndex,
  text,
  imageData,
  isAddSingleLayerCard,
  isAddMultipleLayersCard,
  onAddFiles,
  onRemoveFile,
  onImageClick,
}: {
  imageIndex?: number;
  text?: string;
  imageData?: LayerImageData;
  isAddSingleLayerCard?: boolean;
  isAddMultipleLayersCard?: boolean;
  onAddFiles?: (e: BaseSyntheticEvent) => void;
  onRemoveFile?: () => void;
  onImageClick?: (imageIndex: number) => void;
}) => {
  const isPreviewCard = !isAddMultipleLayersCard && !isAddSingleLayerCard;

  return (
    <CardContainer isAdditionalCard={isAddSingleLayerCard || isAddMultipleLayersCard}>
      {isPreviewCard && (
        <img
          onClick={() => onRemoveFile()}
          className={'remove-image-icon'}
          src={CloseIcon}
          alt={''}
        />
      )}
      <div className={'image-preview-container'}>
        {!isPreviewCard && (
          <AddLayerCardButton isMultiple={isAddMultipleLayersCard} onAddFiles={onAddFiles} />
        )}
        {isPreviewCard && (
          <img
            onClick={() => onImageClick(imageIndex)}
            className={'image-preview-container__image-preview'}
            src={imageData.base64Image}
            alt={''}
          />
        )}
      </div>
      <div>
        <TextBlock className={'layer-description-text'} type={TEXT_BLOCK_TYPE.H4}>
          {imageData?.name || text}
        </TextBlock>
      </div>
    </CardContainer>
  );
};

export default LayerPreviewCard;
