import {AxiosRequestConfig} from 'axios';

import {GenerationConfig} from '../../../store/editorPage/types';
import {getUndefinedVarErrMessage} from '../../../utils/checkEmpty';
import axiosConfig from '../index';

export const configUpload = async (generationConfig: GenerationConfig, metaConfig) => {
  try {
    const requestConfig: AxiosRequestConfig = {
      method: 'post',
      url: 'https://api.ncraftsman.com/config_upload/',

      data: {
        config: generationConfig,
        meta: metaConfig,
      },
    };

    const response = await axiosConfig(requestConfig);
    if (response.data === null || response.data === undefined)
      throw new Error(getUndefinedVarErrMessage({validationData: response.data}));

    return response.data;
  } catch (err) {
    throw err;
  }
};
