import React, {useMemo} from 'react';

import styled from 'styled-components';

import {
  EDITABLE_IMAGE_PROPERTIES,
  SPECIFIC_FIELDS_DEFINITION,
  SPECIFIC_FIELDS_TYPES,
} from '../../constants/imageEdit';

import {LayerImageData} from '../../store/editorPage/types';
import TextBlock, {TEXT_BLOCK_TYPE} from '../uiKit/TextBlock';

const StyledImagePropertiesEditList = styled.div`
  display: flex;
  flex: 1;
  gap: 59px;
  flex-direction: column;

  .properties-list {
    max-width: 1022px;
    width: 100%;

    .property-wrapper > td {
      height: 100%;
      text-align: center;
    }
  }

  .select {
    min-width: 185px;
  }

  .properties-list th div {
    padding-bottom: 20px;
    color: ${({theme}) => theme.colors.mainPurple};
  }
`;

interface ImagePropertiesEditListProps {
  imageIndex: number;
  imageData: LayerImageData;
  onImagePropertyChange: (data: Partial<LayerImageData>) => void;
  allImagesNames: string[];
}

interface ConstructedItem {
  controlElement: JSX.Element;
  propertyKey: string;
  propertyLabel: string;
  value: LayerImageData[keyof LayerImageData];
}

const ImagePropertiesEditList = ({
  imageIndex,
  imageData,
  onImagePropertyChange,
  allImagesNames = [],
}: ImagePropertiesEditListProps) => {
  //TODO refactor this shit

  const constructedDataBeforeDisplay = useMemo(() => {
    const displayData: Array<ConstructedItem> = [];
    Object.entries(imageData || {}).forEach(
      ([propertyKey, value]: [keyof LayerImageData, LayerImageData[keyof LayerImageData]]) => {
        const propertyLabel = EDITABLE_IMAGE_PROPERTIES[propertyKey];
        if (!propertyLabel) return;
        const fieldType = SPECIFIC_FIELDS_DEFINITION[propertyKey] || typeof value;
        let controlElement;
        switch (fieldType) {
          case SPECIFIC_FIELDS_TYPES.TEXT_AREA:
            controlElement = (
              <textarea
                value={value as string | number}
                rows={6}
                onChange={(e) => onImagePropertyChange({[propertyKey]: e.target.value})}
              />
            );
            break;
          case SPECIFIC_FIELDS_TYPES.IMAGES_DROPDOWN:
            controlElement = (
              <select
                className={'select'}
                value={value as string}
                onChange={(e) => onImagePropertyChange({[propertyKey]: e.target.value})}>
                <option value={''}>{''}</option>
                {allImagesNames
                  .filter((imageName) => imageName !== imageData.name)
                  .map((imageName) => (
                    <option value={imageName} selected={imageName === value}>
                      {imageName}
                    </option>
                  ))}
              </select>
            );
            break;
          case 'boolean':
            controlElement = (
              <input
                type={'checkbox'}
                checked={value as boolean}
                onChange={(e) => onImagePropertyChange({[propertyKey]: e.target.checked})}
              />
            );
            break;
          default:
            controlElement = (
              <input
                value={value as string | number}
                onChange={(e) => onImagePropertyChange({[propertyKey]: e.target.value})}
              />
            );
        }
        displayData.push({
          controlElement,
          propertyKey,
          propertyLabel,
          value,
        });
      },
    );
    return displayData;
  }, [imageData, onImagePropertyChange]);

  return (
    <StyledImagePropertiesEditList>
      <table className={'properties-list'}>
        <thead>
          <tr>
            <th>
              <TextBlock type={TEXT_BLOCK_TYPE.H3}>Property name</TextBlock>
            </th>
            <th>
              <TextBlock type={TEXT_BLOCK_TYPE.H3}>Value</TextBlock>
            </th>
            <th>
              <TextBlock type={TEXT_BLOCK_TYPE.H3}>Info</TextBlock>
            </th>
          </tr>
        </thead>
        <tbody>
          {constructedDataBeforeDisplay.map(({controlElement, propertyLabel, value}) => (
            <tr className={'property-wrapper'} key={propertyLabel}>
              <td>
                <TextBlock type={TEXT_BLOCK_TYPE.H3}>{propertyLabel}</TextBlock>
              </td>
              <td>{controlElement}</td>
              {/*<td>*/}

              {/*</td>*/}
            </tr>
          ))}
        </tbody>
      </table>
    </StyledImagePropertiesEditList>
  );
};

export default ImagePropertiesEditList;
